import axios from "../../../constants/axios";
import getAuthHeaders from "../../../lib/getAuthHeaders";

export default function RemoveDummy() {
  return (
    <button
      className="btn-primary font-normal"
      onClick={() => {
        axios.delete('/api/admin/remove-dummy-accounts', getAuthHeaders())
      }}
    >Remove Dummy Accounts</button>
  );
}