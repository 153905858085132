import './styles.scss';


interface TableProps {
    children?: React.ReactNode,
    className?: string
}

export default function Table(props: TableProps) {
    return (
        <div className={`table-wrapper ${props.className}`}>
            <div>
                <table>
                    {props.children}
                </table>
            </div>
        </div>
    )
}

interface FullRowTypes {
    children?: React.ReactNode,
    colSpan?: number,
    className?: string
}

export const FullRow = (props: FullRowTypes) => (
    <tr className={props.className}>
        <td colSpan={props.colSpan || 50}>
            <div className='flex justify-center items-center'>
                {props.children}
            </div>
        </td>
    </tr>
)