export default function Menu(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg {...props} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 3.5H3V10.5H10V3.5Z" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 3.5H14V10.5H21V3.5Z" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 14.5H14V21.5H21V14.5Z" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 14.5H3V21.5H10V14.5Z" stroke="#555555" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}