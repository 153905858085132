import Check from "../../icons/Check";
import { Input } from "../../types/form";
import './styles.scss';


interface PropsType extends Input {
    label?: React.ReactNode
}

export default function Checkbox({ label, className = "", ...props }: PropsType) {
    return (
        <label className={`Checbox-Wrapper ${className}`}>
            <input type="checkbox" className="hidden" {...props} />
            <span className="CheckBox border-gradient">
                <Check /> 
            </span>
            {label && <span className="CheckBox-Label">{label}</span>}
        </label>
    )
}