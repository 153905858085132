'use client';
import { Input } from '../../types/form';
import './styles.scss';
import { useState } from 'react';

export interface PropsType extends Input {
    label?: string,
    helperText?: string,
    classname?: string,
    errorText?: string,
    rows?: number,
}


const withFormField = <P extends object>(FormField: React.ComponentType<P>) => (
    { label, helperText, className = "", errorText, ...props }: PropsType
) => {
    const [status, setStatus] = useState('');
    return (
        <label className={`withFormField form-field ${props.disabled ? "disabled" : ""} ${status} ${className}`}>
            {label && <span>{label} {props.required && <span className='text-red-500'>*</span>}</span>}
            <FormField
                className="FormField p-gradient-btn"
                {...props as P}
                onBlur={() => !status && setStatus('-blur')}
            />
            {helperText && <small className='Helper-Text font-normal'>{helperText}</small>}
            {errorText && <small className='ErrorText'>{errorText}</small>}
        </label>
    )
}

export default withFormField;