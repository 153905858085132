import useClassName from "../../../hooks/useClassName"
import Modal from "../../../ui/Modal"
import Form from "../../../hoc/form/form";
import Input from "../../../ui/Input";
import emailReg from "../../../constants/emailReg";
import Select from "../../../ui/Select";


export default function Add() {
    const tools = useClassName()
    return (
        <>
            <button onClick={tools.onActive} className="btn-primary font-normal">Create New Admin</button>
            <Modal {...tools} title="Create Admin">
                <Form className="grid gap-5 mt-2"
                    api="/api/admin/admin"
                    footer={(loading, className) => (
                        <>
                            <button
                                className={`btn-primary ml-auto px-8 ${className}`}
                                disabled={loading}
                            >Save</button>
                        </>
                    )}
                >
                    <Input name='email' placeholder="Enter Email Address" reg={emailReg} required />
                    <Input name='password' type="password" placeholder="Enter a Password" minLength={6} required />
                    <Select name="role">
                        <option value="admin">Admin</option>
                        <option value="super-admin">Super Admin</option>
                    </Select>
                </Form>
            </Modal>
        </>
    )
}