import { useState } from "react"
import useClassName from "../../../hooks/useClassName"
import Modal from "../../../ui/Modal"
import axios from "../../../constants/axios";
import getAuthHeaders from "../../../lib/getAuthHeaders";

interface PropsType {
    adminId: string,
}

export default function Delete(props: PropsType) {
    const tools = useClassName()
    const [isRemoved, setRemoved] = useState(false);
    if(isRemoved) return <p className="text-red-500">Removed</p>
    return (
        <>
            <button onClick={tools.onActive} className="btn-primary font-normal">Delete</button>
            <Modal {...tools} title="Are you sure">
                <p>Are you sure you want to remove all user information?</p>
                <div className="flex justify-end gap-5">
                    <button
                        onClick={() => {
                            axios.delete(`/api/admin/remove-school/${props.adminId}`, getAuthHeaders())
                            setRemoved(true)
                        }}
                     className="btn-primary after:bg-red-500 text-white">Delete</button>
                    <button className="btn-primary" onClick={tools.onClose}>Cancel</button>
                </div>
            </Modal>
        </>
    )
}