import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import routes from './routes.tsx'
import './scss/index.scss';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    {routes}
  </StrictMode>,
)
