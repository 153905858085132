'use client';
import { FormEvent, useState, DetailedHTMLProps, FormHTMLAttributes } from "react";
import './form.scss';
import getError from "../../lib/getError";
import { AxiosError } from "axios";
import axios from '../../constants/axios';
import getAuthHeaders from "../../lib/getAuthHeaders";

type FormType = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>


// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ObjectType { [key: string]: any }

export interface FormParamType {
    values: ObjectType,
    onSuccess: Func,
    onFailure: (err: AxiosError | string) => void,
    onStop: () => void,
    form: HTMLFormElement,
    onProgess: Func,
    onInvalid: (name: string) => void,
    formData: FormData
}

export interface FormPropsType extends Omit<FormType, 'onSubmit'> {
    footer?: (loading: boolean | undefined, className: string) => React.ReactNode;
    onSubmit?: (data: FormParamType) => void,
    error?: string,
    api?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onResponse?: (response: any, data: FormParamType) => void;
}

interface StateType {
    err?: string,
    message?: string,
    loading?: boolean | string
}

type Func = (message?: string) => void



const Form = ({ footer, onSubmit, error = "Please fix above erros!", children, api, method, onResponse, ...props }: FormPropsType) => {
    const [{ err, loading, message }, setStatus] = useState<StateType>({});

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) return;
        const form = e.target as HTMLFormElement;
        if (!form.classList.contains('submitted')) form.classList.add('submitted');
        if (!form.checkValidity()) return;
        const formData = new FormData(form);
        const values = {} as ObjectType;
        const keys = [...formData.keys()];
        for (const key of keys) {
            const elm = form[key] as HTMLInputElement;
            if (elm.classList?.contains('invalid')) return;
            if (elm.type === 'file') values[key] = elm.files;
            else if (!elm.type && typeof elm.value === "string") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const child = elm[0];
                values[key] = child && child.type === 'radio' ? formData.get(key) : formData.getAll(key);
            }
            else values[key] = elm.value;
        }

        setStatus({ loading: true })

        const data: FormParamType = {
            values,
            formData,
            onSuccess: message => setStatus({ message }),
            onFailure: err => setStatus({ err: typeof err === 'string' ? err : getError(err) }),
            onStop: () => setStatus({}),
            form,
            onProgess: (name) => setStatus({ loading: name }),
            onInvalid: (name: string) => {
                form[name].classList.add('invalid')
                setStatus({})
            }
        }

        try {
            if (api) {
                const res = await axios({ 
                    method: method || 'POST', 
                    url: api, 
                    data: values,
                    headers: getAuthHeaders().headers
                 });
                if (onResponse) onResponse(res.data, data)
                else setStatus({ message: res.data.message })
            }
            if (onSubmit) await onSubmit(data);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        catch (err: any) {
            console.log(err)
            setStatus({ err: getError(err) })
        }
    }
    return (
        <form onSubmit={onFormSubmit} noValidate={true} {...props}>
            {children}
            {err && <p className="text-red-500 col-span-full">{err}</p>}
            <p className="Form-Message hidden text-red-500 col-span-full">
                {error}
            </p>
            {typeof loading === 'string' && <p className="text-orange-500 col-span-full dots-loader gap-1">{loading}</p>}
            {message && <p className="text-green-500 col-span-full">{message}</p>}
            {footer && footer(loading ? true : false, loading ? "loading" : "")}
        </form>
    )
}

export default Form;