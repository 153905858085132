import { useEffect } from "react"
import useHTTP from "../../../hooks/useHTTP"

interface PropsType {
    _id: string,
}

export default function Reminder(props: PropsType) {
    const { data, loading, onSubmit, setStatus } = useHTTP(`/api/admin/invoice-reminder/${props._id}`)
    useEffect(() => {
        if (data) {
            setTimeout(() => {
                setStatus({ loading: false, data: null, params: {} })
            }, 3000)
        }
    }, [data, setStatus])
    return data ? <span className="text-green-500">Sent</span> : (
        loading ? <div className="loader my-3 h3" /> : <button onClick={() => onSubmit({ isToken: true, method: 'POST' })} className="btn-primary font-normal">Send Reminder</button>
    )
}