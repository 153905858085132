import { Link } from "react-router-dom";
import Header from "../components/header/header";
import Registeration from "../components/registeration/registeration";
import QuickQoute from "./QuickQoute";

export default function Register() {
  return (
    <>
      <Header />
      <div className="pt-page-0 relative cont-v2">
        <div className="absolute flex h3 justify-between left-[3em] right-[3em] bottom-4">
          <div className="circle" />
          <div className="circle" />
        </div>
      </div>
      <p className="bg-gradient-0 shadow-1 cont-p text-center text-lg py-4 font-semibold text-white">
        Special Offer: 30% discount on all school accounts! Pay per user
        annually.
      </p>
      <div className="bg-gradient-light cont-p py-page-0 text-center">
        <h1 className="h1 text-gradient-0">Transform Your Classroom <br className="md:block hidden" /> with Artziii</h1>
        <p className="text-gray-0 text-xl lg:mt-3 mt-2 mb-5 lg:mb-7">Empower your students with our fun and innovative educational software!</p>
        <div className="flex gap-4 justify-center">
          <Link to="" className="btn-primary">Get Started</Link>
          <QuickQoute />
        </div>
      </div>
      <Registeration />
    </>
  );
}
