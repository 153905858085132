import DashboardLayout from "../../hoc/DashboardLayout";
import useHTTP from "../../hooks/useHTTP";
import User from "../../types/user";
import Input from "../../ui/Input";
import Table, { FullRow } from "../../ui/Table";

const Main = () => {
    const { data, err, loading, onSubmit } = useHTTP('/api/admin/users', { active: true, isToken: true })
    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onSubmit({ params: { page: 1, search: e.target.search.value } })
                }}
                className="flex gap-4 mt-4">
                <Input className="flex-1" name="search" placeholder="Search accounts..." />
                <button disabled={loading} className="btn btn-primary font-medium">Search</button>
            </form>
            <Table className="my-5">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Creation Date</th>
                        <th>Role</th>
                        <th>Invoice Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data && data.users.map((user: User) => (
                        <tr key={user._id}>
                            <td className="capitalize">{user.name} {user.lastName || ""}</td>
                            <td>{user.teacherEmail || user.email}</td>
                            <td>{user.createdAt && new Date(user.createdAt).toLocaleDateString()}</td>
                            <td>{user.role}</td>
                            <td></td>
                            <td><button className="btn-primary font-semibold">Manage</button></td>
                        </tr>
                    ))}
                    {loading && (
                        <FullRow>
                            <div className="loader h1 my-2" />
                        </FullRow>
                    )}
                    {err && <FullRow className="text-red-500">{err}</FullRow>}
                </tbody>
            </Table>
            {data && (
                <div className="flex justify-center gap-3 items-center">
                    {data.page > 1 && <button disabled={loading} onClick={() => onSubmit({ params: { page: data.page - 1 } })} className="btn-primary">Previous</button>}
                    Page {data.page || 1}
                    {data.totalPages > data.page && (
                        <button disabled={loading} className="btn-primary" onClick={() => onSubmit({ params: { page: data.page + 1 } })}>Next</button>
                    )}
                </div>
            )}
        </>
    )
}

export default function Account() {
    return (
        <DashboardLayout title="Account Management">
            <div className="border-gradient p-8 shadow-1">
                <h5 className="h5">All Accounts</h5>
                <Main />
            </div>
        </DashboardLayout>
    )
}