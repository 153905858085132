interface PropsType {
    children?: React.ReactNode,
    title: string
}

export default function DashboardLayout(props: PropsType) {
    return (

        <div className="flex-1 border-l p-8">
            <h1 className="h3 mb-8 font-semibold">{props.title}</h1>
            {props.children}
        </div>
    )
}