export interface Admin {
    _id: string,
    token: string,
    email: string,
    createdAt: string,
    expiresIn: number,
}

const admin: Admin = {
    _id: "",
    token: "",
    email: "",
    createdAt: "",
    expiresIn: 0
}


export default admin;