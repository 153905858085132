import User from "../../types/user";

export default function getCSVUsers(file?: File, cb?: (users: User[]) => void) {
    if (file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            const text = e.target?.result as string;
            const lines = text.split('\n');
            const headers = lines[0].split(',').map(key => {
                const truncatedStr = key.split('/')[0].trim();
                return truncatedStr.toLowerCase()
                    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase())
                    .replace(/^[A-Z]/, chr => chr.toLowerCase());
            });
            const users = lines.slice(1).map(line => {
                const user = line.split(',');
                return headers.reduce((acc, header, i) => {
                    acc[header] = user[i];
                    return acc;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }, {} as any);
            });
            const newUsers = []
            for (const user of users) {
                if (user.firstName && user.lastName) {
                    if (user.accountType?.includes('eacher')) {
                        user.role = 'teacher';
                        user.password = user.teacherPassword;
                        delete user.accountType;
                        delete user.teacherPassword;
                    }
                    else if (!user.role) user.role = 'student';
                    newUsers.push(user);
                }
            }
            console.log(newUsers)
            if (cb) cb(newUsers);
        };
    }
}