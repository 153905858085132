import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Register from "./pages/register";
import Login from "./pages/login";
// import Account from "./pages/account";
import Admin from "./pages/admin";
import Dashboard from "./pages/admin/dashboard";
import Account from "./pages/admin/account";
import Invoices from "./pages/admin/invoices/invoices";
import Admins from "./pages/admin/admins";

const router = createBrowserRouter([
  {
    path: "/signup",
    element: <Register />,
  },
  {
    path: '/admin',
    element: <Admin />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'accounts',
        element: <Account />
      },
      {
        path: 'invoices',
        element: <Invoices />
      },
      {
        path: 'admins',
        element: <Admins />
      }
    ],
  },
  {
    path: "/",
    element: <Login />,
  },
]);




export default <RouterProvider router={router} />;