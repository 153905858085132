import axios from '../../../constants/axios'
import Delete from "./Delete";
import getAuthHeaders from "../../../lib/getAuthHeaders";
import Reminder from "./Reminder";

export interface Invoice {
    _id: string,
    id: string,
    name: string,
    amount: number,
    startAt: string,
    status: 'pending' | 'paid' | 'cancelled',
    adminId: string
    email: string
}

const Item = (props: Invoice) => {
    return (
        <tr>
            <td>{props.id}</td>
            <td>{props.name}</td>
            <td>{props.email}</td>
            <td>${props.amount}</td>
            <td>{new Date(props.startAt).toLocaleString()}</td>
            <td>
                <select
                    onChange={e => axios.put(`/api/admin/invoice-status/${props._id}`, { status: e.target.value }, getAuthHeaders())}
                    className="outline-none"
                    defaultValue={props.status === 'pending' ? new Date(props.startAt) < new Date() ? 'overdue' : 'pending' : props.status}
                >
                    <option value="pending">Pending</option>
                    <option value="paid">Paid</option>
                    <option value="overdue">Overdue</option>
                    <option value="cancelled">Cancelled</option>
                </select>
            </td>
            <td>
                <div className="flex items-center gap-3">
                    <Reminder _id={props._id} />
                    <Delete adminId={props.adminId} />
                </div>
            </td>
        </tr>
    )
}

export default Item;