import { useEffect, useState } from "react";
import AdminContext from ".";
import defaultAdmin, { Admin } from './admin';
import ProviderType from "../ProviderType";


const initialAdmin = localStorage.getItem("admin");

export default function AdminProvider(props: ProviderType) {
    const [admin, setAdmin] = useState(initialAdmin ? JSON.parse(initialAdmin) : defaultAdmin);
    useEffect(() => {
        if(admin.expiresIn) {
            const expiresIn = new Date(admin.expiresIn).getTime() - new Date().getTime();
            const timer = setTimeout(() => {
                clearTimeout(timer);
                localStorage.removeItem("admin");
                setAdmin(defaultAdmin);
            }, expiresIn);
        }
    }, [admin.expiresIn])
    return (
        <AdminContext.Provider
            value={{
                ...admin,
                login: (admin: Admin) => {
                    console.log(admin)
                    localStorage.setItem("admin", JSON.stringify(admin));
                    setAdmin(admin);
                }
            }}
        >
            {props.children}
        </AdminContext.Provider>
    )
}