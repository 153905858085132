import { Link, NavLink } from "react-router-dom"
import Menu from "../../icons/Menu"
import Users from "../../icons/Accounts"
import File from "../../icons/File"
import Gear from "../../icons/Gear"
import { Outlet } from "react-router-dom"
import useAdmin from "../../context/admin/useAdmin"
import Form from "../../hoc/form/form"
import Input from "../../ui/Input"
import emailReg from "../../constants/emailReg"
import AdminProvider from "../../context/admin/provider"


function Main() {
    const admin = useAdmin();
    if (!admin.expiresIn) {
        return (
            <div className="flex items-center justify-center min-h-dvh">
                <Form
                    onResponse={admin.login}
                    api="/api/admin/login"
                    className="sm:p-12 p-6 bg-white shadow-1 w-full sm:w-[initial] grid gap-8 md:gap-12"
                    footer={(loading, className) => (
                        <button
                            className={`btn-primary ${className}`}
                            disabled={loading}
                        >Login</button>
                    )}
                >
                    <h1 className="h4">Admin</h1>
                    <Input placeholder="Email" name="email" className="sm:min-w-96" reg={emailReg} />
                    <Input placeholder="Password" type="password" minLength={6} required errorText="Password must be minimum 6 characters long!" name="password" />
                </Form>
            </div>
        )
    }
    return (
        <div className="flex">
            <div className="px-8 pt-6 h-dvh sticky top-0 flex gap-6 flex-col">
                <Link to='/' className="mx-auto mb-1">
                    <img src="/logo.png" className="w-32 mb-1" alt="" />
                    <span className="h4">Education</span>
                </Link>
                <div className="flex flex-col gap-4">
                    <NavLink to='' end className="nav-link justify-start">
                        <Menu /> Dashboard
                    </NavLink>
                    <NavLink to="accounts" className="nav-link justify-start">
                        <Users /> Accounts
                    </NavLink>
                    <NavLink to="invoices" className="nav-link justify-start">
                        <File /> Invoices
                    </NavLink>
                    <NavLink to="settings" className="nav-link justify-start">
                        <Gear /> Settings
                    </NavLink>
                </div>
            </div>
            <Outlet context={admin} />
        </div>
    )
}


export default function Admin() {
    return (
        <AdminProvider>
            <Main />
        </AdminProvider>
    )
}