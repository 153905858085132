import plans from '../../constants/prices';
import './plan.scss';

interface PropsType {
    title: "Lite" | "Pro",
    // onChange: () => void
}

export default function Plan(props: PropsType) {
    const isLite = props.title === "Lite";
    return (
        <label className='Signup-Plan border-gradient'>
            {/* <input onChange={props.onChange} type="radio" required className='hidden' name="plan" value={props.title} /> */}
            <span className="h4">{props.title} Plan</span>
            <span>
                <span className="h3">${isLite ? 9 : 23}.99/month</span> 
                <small> Per User</small>
            </span>
            <small>Billed at ${isLite ? plans.lite : plans.pro}/annually per user</small>
            <span className="mt-5">{isLite ? "No Ebooks" : "Professional Ebooks (Epub)"}</span>
            <span>{isLite ? 10 : 24}0,000 words/month AI Writing</span>
            <span className="mb-5">Unlimited AI Art</span>
            {/* <span className="flex items-center justify-center gap-3">
                <span className='Radio-Box' /> Select Plan
            </span> */}
        </label>
    )
}