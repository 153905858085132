const plans = {
    pro: 287.88,
    lite: 119.99,
}

export const discountPrices = {
    pro: +(plans.pro * 0.7).toFixed(2),
    lite: +(plans.lite * 0.7).toFixed(2),
}

export default plans;