import axios from "../../constants/axios";
import Form from "../../hoc/form/form"
import Input from "../../ui/Input"
import Plans from "./plans"
import { useNavigate } from "react-router-dom"
import emailReg from "../../constants/emailReg"
import getCSVUsers from "./getCSVUsers"

// interface PropsType {
//     className?: string
// }

export default function Registeration() {
    const navigate = useNavigate();
    return (
        <Form className="cont-p py-10 bg-white flex flex-col overflow-hidden gap-4"
            onSubmit={(props) => {
                const files = props.values.users;
                if (files && files[0]) {
                    getCSVUsers(files[0], users => {
                        props.values.users = users;
                        axios.post('/api/education/users/register', props.values)
                            .then(res => {
                                props.onSuccess(res.data.message)
                                setTimeout(() => {
                                    navigate("/", { replace: true })
                                }, 1000)
                            })
                            .catch(props.onFailure)
                    });
                }
                else {
                    props.onFailure("Please upload a CSV file")
                }

            }}
            footer={(loading, className) => (
                <button className={`btn-primary ${className}`} disabled={loading}>Sign Up</button>
            )}
        >
            <h2 className="h4 mb-2">Sign Up for Artziii Education</h2>
            <Input placeholder="School Name" name="name" required />
            <Input placeholder="Street Address" name="address" required />
            <Input placeholder="City" name="city" required />
            <Input placeholder="State/Province" name="state" required />
            <Input placeholder="Zip/Postal Code" name="zipCode" required />
            <Input placeholder="Country" name="country" required />
            <Input placeholder="Billing Admin Email Address" name="email" reg={emailReg} required />
            <h3 className="text-lg font-bold">Available Plans</h3>
            <Plans />
        </Form>
    )
}