'use client';
import withFormField, { PropsType } from "../hoc/withFormField";
import { useEffect, useState } from "react";

interface Props extends PropsType {
    reg?: RegExp,
}

const Main = withFormField(props => <input {...props} />)

const Input = ({ reg, value, defaultValue, onChange, className, ...props }: Props) => {
    const [v, setValue] = useState(defaultValue || value || "");
    const invalid = reg && typeof v === 'string' && !reg.test(v) ? 'invalid' : '';
    useEffect(() => {
        if (typeof value === 'string') setValue(value);
    }, [value])
    return (
        <Main
            value={v}
            className={`${invalid} ${className}`}
            required={!!reg}
            {...props}
            onChange={e => {
                setValue(e.target.value);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onChange && onChange(e);
            }}
        />
    )
}

export default Input;