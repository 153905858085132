import Portal from "../../hoc/Portal";
import Close from "../../icons/Close";
import './styles.scss'

interface PropsType {
    title?: string,
    children?: React.ReactNode,
    className?: string,
    onClose?: () => void
}

export default function Modal(props: PropsType) {
    return props.className && (
        <Portal id="modals">
            <div className={`fixed top-0 left-0 w-full h-full z-10 flex items-center justify-center backdrop`}>
                <div className={`${props.className} h-full md:h-[initial] Modal bg-white shadow-0 flex flex-col gap-5 max-w-full w-[36rem] overflow-auto border-gradient p-5 border rounded-md`}>
                    <div className="flex justify-between gap-5">
                        <h1 className="h4">{props.title}</h1>
                        <button className="btn-icon text-xs" onClick={props.onClose}>
                            <Close />
                        </button>
                    </div>
                    {props.children}
                </div>
            </div>
        </Portal>
    )
}