import { Link } from "react-router-dom";

export default function Header() {
    return (
        <header className="lg:py-4 py-2 cont-p-v2 bg-white shadow-0 flex items-center">
            <Link to="/" className="h3 flex items-center gap-[0.5em]">
                <img src="/logo.png" alt="" className="h-[2em]" />
                Education
            </Link>
        </header>
    )
}
