import { discountPrices } from "../constants/prices";

type AccountType = number | string

const prices = {
    getProPrices: (proAccounts: AccountType  = 0) => (+proAccounts * discountPrices.pro).toFixed(2),
    getLitePrices: (liteAccounts: AccountType = 0) => (+liteAccounts * discountPrices.lite).toFixed(2),
    getTotal: (liteAccounts: AccountType = 0, proAccounts: AccountType = 0) => ((discountPrices.lite * +liteAccounts) + (discountPrices.pro * +proAccounts)).toFixed(2),
}

export default prices;