import DashboardLayout from "../../../hoc/DashboardLayout";
import useHTTP from "../../../hooks/useHTTP";
import Table, { FullRow } from "../../../ui/Table";
import Add from "./Add";
import Delete from "./Delete";
import RemoveDummy from "./RemoveDummy";


interface Admin {
    _id: string;
    email: string;
    role: 'admin' | 'super-admin';
}


const Main = () => {
    const { err, loading, data } = useHTTP('/api/admin/admins', { active: true, isToken: true })
    return (
        <>
            <div className="flex gap-4 mt-3">
                <Add />
                <RemoveDummy />
            </div>
            <Table className="my-5">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((admin: Admin) => (
                        <tr key={admin._id}>
                            <td>{admin.email}</td>
                            <td>{admin.role}</td>
                            <td><Delete _id={admin._id} /></td>
                        </tr>
                    ))}
                    {loading && (
                        <FullRow>
                            <div className="loader h1 my-2" />
                        </FullRow>
                    )}
                    {err && <FullRow className="text-red-500">{err}</FullRow>}
                </tbody>
            </Table>
        </>
    )
}

export default function Admins() {
    return (
        <DashboardLayout title="Admins">
            <div className="border-gradient p-8 shadow-1">
                <h5 className="h5">Admins</h5>
                <Main />
            </div>
        </DashboardLayout>
    )
}