import DashboardLayout from "../../hoc/DashboardLayout";
import useHTTP from "../../hooks/useHTTP";
import User from "../../types/user";

interface UserType extends User {
    invoice?: number,
    totalUsers: number
}

const Main = () => {
    const { data, err, loading } = useHTTP('/api/admin/dashboard', { active: true, isToken: true })
    if (loading) return (
        <div className="flex my-96">
            <div className="loader h1 m-auto" />
        </div>
    )
    if (err) return (
        <div className="text-red-500 text-center my-96 h3 font-medium">
            {err}
        </div>
    )
    let pendingInvoices = 0, overdue = 0, totalUsers = 0;
    const today = new Date(), users = data.users;
    today.setMonth(today.getMonth() + 1);
    users.forEach((user: UserType) => {
        if (user.invoice) {
            const date = new Date(user.createdAt)
            if (date > today) overdue++;
            else pendingInvoices++;
        }
        totalUsers += user.totalUsers
    })
    return (
        <>
            <div className="border-gradient p-6 shadow-2">
                <h4 className="h5 font-semibold mb-4">Overview</h4>
                <div className="grid text-center grid-cols-3 gap-4">
                    <div className="border-gradient p-4">
                        <h5 className="h4">{totalUsers}</h5>
                        <small>Total Accounts</small>
                    </div>
                    <div className="border-gradient p-4">
                        <h5 className="h4">{pendingInvoices}</h5>
                        <small>Pending Invoices</small>
                    </div>
                    <div className="border-gradient p-4">
                        <h5 className="h4">{overdue}</h5>
                        <small>Overdue Accounts</small>
                    </div>
                </div>
            </div>
            <div className="border-gradient p-6 shadow-2 my-6 flex justify-between">
                <h4 className="h5 font-semibold mb-4">Recent Accounts</h4>
                <button className="btn-primary font-normal">Expand</button>
            </div>
            <div className="border-gradient p-6 shadow-2 flex justify-between">
                <h4 className="h5 font-semibold mb-4">Invoices</h4>
                <button className="btn-primary font-normal">Expand</button>
            </div>
        </>
    )
}

export default function Dashboard() {
    return (
        <DashboardLayout title="Account Management Dashboard">
            <Main />
        </DashboardLayout>
    )
}