import { useState } from "react";
import Input from "../../ui/Input";
import Plan from "./plan";
import Checkbox from "../../ui/CheckBox";
import getCSVUsers from "./getCSVUsers";
import prices from "../../lib/getPrices";

interface StateType {
    pro: number;
    lite: number;
    teachers: number;
    students: number;
}

export default function Plans() {
    const [state, setPlans] = useState<StateType>()
    return (
        <>
            <div className="flex flex-wrap justify-center gap-4">
                <Plan title="Lite" />
                <Plan title="Pro"/>
                {/* <small className="ErrorText">Please select at least 1 plan</small> */}
            </div>
            <Checkbox required label={<>By selecting you accept our <a href="https://artziii.com/artziii-education-program-terms-of-service/" className="text-gradient-0">Terms of Service</a></>} />
            <h3 className="text-lg font-bold">Teacher CSV Upload</h3>
            <a href="/Artziii Education Account Sign-up Sample CSV.csv" download className="text-gradient-0 underline hover:no-underline">Download Sample CSV Template</a>
            <p>Upload a CSV file to create student accounts</p>
            <Input
                onChange={e => {
                    const files = e.target.files;
                    if (files) {
                        getCSVUsers(files[0], users => {
                            let pro = 0;
                            let teachers = 0;
                            users.forEach(user => {
                                if (user.plan === 'Pro') pro++;
                                if (user.password) teachers++
                            });
                            setPlans({ pro, lite: users.length - pro, teachers, students: users.length - teachers });
                        });
                    }
                }}
                type="file" accept=".csv" name="users" required
            />
            {state && (
                <h3 className="h4 text-center">
                    Total Pro Accounts: {state.pro} <br />
                    Total Lite Accounts: {state.lite} <br />
                    Estimated annual cost: ${prices.getTotal(state.lite, state.pro)}<br />
                    (Including {state.teachers} teacher accounts and {state.students} student accounts)
                </h3>
            )}
            {/* {price ? <button type="button" onClick={() => setquote(!quote)} className="btn-primary">Get Instant quote</button> : ""}
            {quote && price ? (
                <h3 className="h4 text-center">
                    Esitimated Annual Cost: ${((price * students) + price * 0.7).toFixed(2)} <br />
                    (Includes 1 teacher account and {students} student accounts)
                    <br />
                    (30% discount applied)
                </h3>
            ) : ""} */}
        </>
    )
}