import axios from "../constants/axios";
import { useEffect, useState } from "react"
import getError from "../lib/getError";
import getAuthHeaders from "../lib/getAuthHeaders";


interface ObjectType {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
}

interface SubmitTypes {
    params?: ObjectType,
    data?: ObjectType,
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
    isToken?: boolean
}


export interface OptionsType extends SubmitTypes {
    active?: boolean,
}

interface StateType {
    loading?: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    err?: string,
    params: ObjectType
}

const useHTTP = (api: string, options: OptionsType = {}) => {
    const [status, setStatus] = useState<StateType>({ loading: options.active, params: options.params || {} });
    const onSubmit = (op?: SubmitTypes) => {
        const allOptions = {...op, ...options};
        const params = {...status.params, ...allOptions.params};
        setStatus({ loading: true, data: status.data, params });
        axios({
            method: allOptions.method || 'GET',
            url: api,
            params,
            data: allOptions.data,
            headers: allOptions.isToken ? getAuthHeaders().headers : {}
        })
            .then(res => setStatus({ data: res.data, params }))
            .catch(err => {
                const message =  getError(err)
                if(message === 'Access Denied') {
                    localStorage.removeItem('admin')
                    window.location.href = '/admin'
                }
                else setStatus({ err: message, params })
            })
    }
    useEffect(() => {
        if (options.active) onSubmit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options.active, options.params])
    return { ...status, onSubmit, setStatus };
}

export default useHTTP;