import DashboardLayout from "../../../hoc/DashboardLayout";
import useHTTP from "../../../hooks/useHTTP";
import Input from "../../../ui/Input";
import Table, { FullRow } from "../../../ui/Table";
import Item, { Invoice } from "./Item";



const Main = () => {
    const { err, loading, data, onSubmit } = useHTTP('/api/admin/invoices', { active: true, isToken: true })
    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    onSubmit({ params: { page: 1, search: e.target.search.value } })
                }}
                className="flex gap-4 mt-4">
                <Input disabled={loading} className="flex-1" name="search" placeholder="Search accounts..." />
                <button disabled={loading} className="btn btn-primary font-medium">Search</button>
            </form>
            <Table className="my-5">
                <thead>
                    <tr>
                        <th>Invoice ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Amount</th>
                        <th>Due Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.invoices.map((invoice: Invoice) => <Item {...invoice} key={invoice._id} />)}
                    {loading && (
                        <FullRow>
                            <div className="loader h1 my-2" />
                        </FullRow>
                    )}
                    {err && <FullRow className="text-red-500">{err}</FullRow>}
                </tbody>
            </Table>
            {data && (
                <div className="flex justify-center gap-3 items-center">
                    {data.page > 1 && <button disabled={loading} onClick={() => onSubmit({ params: { page: data.page - 1 } })} className="btn-primary">Previous</button>}
                    Page {data.page || 1}
                    {data.totalPages > data.page && (
                        <button disabled={loading} className="btn-primary" onClick={() => onSubmit({ params: { page: data.page + 1 } })}>Next</button>
                    )}
                </div>
            )}
        </>
    )
}

export default function Invoices() {
    return (
        <DashboardLayout title="Invoices">
            <div className="border-gradient p-8 shadow-1">
                <h5 className="h5">Invoices</h5>
                <Main />
            </div>
        </DashboardLayout>
    )
}