import { createPortal } from "react-dom";

interface PropsType {
    children?: React.ReactNode,
    id: string
}

export default function Portal(props: PropsType) {
    const container = document.getElementById(props.id);
    if(container) return createPortal(props.children, container)
    return "";
}