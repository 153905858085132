import { useState } from "react";

export interface ClassProps {
    className: "" | "active" | "closed";
    onActive: () => void;
    onClose: () => void;
}

export default function useClassName() {
    const [className, setClass] = useState<"active" | "closed" | "">("");

    return {
        className,
        onActive: () => setClass("active"),
        onClose: () => {
            setClass("closed");
            setTimeout(() => {
                setClass("");
            }, 400);
        }
    }
}